import { useState, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { DefaultStore } from '../model/store.model';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/LoginPage';

// utils
import localStorageAvailable from '../utils/localStorageAvailable';

import { isValidToken, setSession } from './utils';
import { signIn } from '../slices/authSlice';
import { useAppDispatch } from '../app/hooks';
import { PATH_AUTH } from '../routes/paths';
import { fetchUserProfile } from '../slices/userSlice';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  // const { isInitialized } = useAuthContext();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useSelector((state: DefaultStore) => state.auth);

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const storageAvailable = localStorageAvailable();

  // if (!isInitialized) {
  //   return <LoadingScreen />;
  // }
  useEffect(() => {
    if (!isAuthenticated) {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (accessToken) {
        if (isValidToken(accessToken)) {
          setSession(accessToken);
          if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
          }
          // dispatch(fetchUserProfile());
        } else {
          window.location.href = PATH_AUTH.login;
          setSession(null);
        }
      } else {
        window.location.href = PATH_AUTH.login;
        setSession(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
