import { styled } from '@mui/material';

export const IconWrapper = styled('div')`
  padding: 5px;
  border-radius: 50%;
  background-color: #dbd5d5;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarWrapper = styled('div')`
  input {
    display: none;
  }
  label {
    bottom: 17px;
    position: relative;
    left: 54px;
    cursor: pointer;
  }
  svg {
    font-size: 18px;
  }
`;
