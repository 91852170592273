import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
export const Home = Loadable(lazy(() => import('../pages/Home')));
export const Teams = Loadable(lazy(() => import('../pages/Teams/List/Teams')));
export const TeamDetails = Loadable(lazy(() => import('../pages/Teams/Detail/TeamDetails')));
export const Employees = Loadable(lazy(() => import('../pages/Employee/List/Employee')));
export const EmployeeDetails = Loadable(lazy(() => import('../pages/Employee/Detail/EmployeeDetails')));
export const Interviews = Loadable(lazy(() => import('../pages/Interviews/List/Interviews')));
export const CandidateForm = Loadable(lazy(() => import('../pages/Interviews/Edit/CandidateForm')));
export const InterviewRounds = Loadable(lazy(() => import('../pages/Interviews/List/Rounds')));
export const InterviewDetails = Loadable(lazy(() => import('../pages/Interviews/Detail/InterviewDetail')));
export const Attendance = Loadable(lazy(() => import('../pages/Attendance/UserAttendanceList')));
export const Permissions = Loadable(lazy(() => import('../pages/Permission/PermissionList')));
export const Sessions = Loadable(lazy(() => import('../pages/Session/List/Session')));
export const SessionAttendance = Loadable(lazy(() => import('../pages/Session/Attendance/List/Attendance')));
export const UserProfile = Loadable(lazy(() => import('../pages/Profile/Profile')));
export const AddProjects = Loadable(lazy(() => import('../pages/Profile/Edit/ProjectsForm')));
export const AddCareer = Loadable(lazy(() => import('../pages/Profile/Edit/CareerForm')));
export const EditProfile = Loadable(lazy(() => import('../pages/Profile/Edit/PersonalInfoForm')));
export const EditSkills = Loadable(lazy(() => import('../pages/Profile/Edit/SkillsForm')));
export const SessionList = Loadable(lazy(() => import('../pages/Session/List/SessionList')));
export const Tasks = Loadable(lazy(() => import('../pages/Tasks/List/TaskList')));
export const DailyStatus = Loadable(lazy(() => import('../pages/DailyStatus/List/StatusList')));
export const AddTask = Loadable(lazy(() => import('../pages/Tasks/Edit/CreateTaskPage')));
export const AssignedTask = Loadable(lazy(() => import('../pages/AssignedTasks/List/TaskList')));
export const ListTrainees = Loadable(lazy(() => import('../pages/Trainees/List/TraineeList')));
export const TaskDetails = Loadable(lazy(() => import('../pages/Tasks/Detail/TaskDetails')));
export const TaskMapDetails = Loadable(lazy(() => import('../pages/Tasks/Detail/TaskMapDetails')));

export const FinalReview = Loadable(lazy(() => import('../pages/FinalReviews/ListReviews')));

export const FinalReviewAdd = Loadable(lazy(() => import('../pages/FinalReviews/AddReviews')));

export const FinalReviewEdit = Loadable(lazy(() => import('../pages/FinalReviews/EditReviews')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
