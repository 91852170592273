import { forwardRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Badge, Avatar, Button } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
//
import { CustomAvatarProps } from './types';
import { IconWrapper, AvatarWrapper } from './styles';

// ----------------------------------------------------------------------

const getCharAtName = (name: string) => name && name.charAt(0).toUpperCase();

const getColorByName = (name: string) => {
  if (['A', 'J', 'H', 'L', 'Q'].includes(getCharAtName(name))) return 'primary';
  if (['F', 'G', 'T', 'N', 'W'].includes(getCharAtName(name))) return 'info';
  if (['K', 'D', 'Y', 'I', 'O'].includes(getCharAtName(name))) return 'success';
  if (['P', 'E', 'R', 'S', 'U'].includes(getCharAtName(name))) return 'warning';
  if (['V', 'B', 'X', 'M', 'Z'].includes(getCharAtName(name))) return 'error';
  return 'default';
};

// ----------------------------------------------------------------------

const CustomAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
  (
    { value, onChange, showHintText, color, name = '', BadgeProps, children, sx, ...other },
    ref
  ) => {
    const theme = useTheme();

    const charAtName = getCharAtName(name);

    const colorByName = getColorByName(name);

    const colr = color || colorByName;

    const [selectedImage, setSelectedImage] = useState(value);

    const handleImageChange = (e: any) => {
      const file = e.target.files[0];
      if (file) {
        setSelectedImage(URL.createObjectURL(file));
        if (onChange) onChange(file);
      }
    };

    useEffect(() => {
      setSelectedImage(value);
    }, [value]);

    const renderContent =
      colr === 'default' ? (
        <Avatar src={selectedImage} ref={ref} sx={sx} {...other}>
          {name && charAtName}
          {children}
        </Avatar>
      ) : (
        <Avatar
          src={selectedImage}
          ref={ref}
          sx={{
            color: theme.palette[colr]?.contrastText,
            backgroundColor: theme.palette[colr]?.main,
            fontWeight: theme.typography.fontWeightMedium,
            ...sx,
          }}
          {...other}
        >
          {name && charAtName}
          {children}
        </Avatar>
      );

    return BadgeProps ? (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        {...BadgeProps}
      >
        {renderContent}
      </Badge>
    ) : (
      <AvatarWrapper>
        {renderContent}
        {onChange && (
          <>
            <label htmlFor={`avatar-upload-${name}`}>
              <IconWrapper
                onMouseEnter={() => showHintText && showHintText(true)}
                onMouseLeave={() => showHintText && showHintText(false)}
              >
                <CameraAlt />
              </IconWrapper>
              <input
                accept="image/*"
                id={`avatar-upload-${name}`}
                type="file"
                onChange={handleImageChange}
              />
            </label>
          </>
        )}
      </AvatarWrapper>
    );
  }
);

export default CustomAvatar;
