import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { TaskState } from '../model/tasks.model';

const initialState: TaskState = {
  isLoading: false,
  isTaskLoading: false,
  isStatusLogLoading:false,
  tasksData: [],
  taskDetails: {},
  taskDocuments:{},
  statusLogs:{},
  locations:[],
  assignedTasks: [],
  tasksStatuses:[],
  error: {},
  totalData: 0,
  draftCount: 0,
  activeCount: 0,
  totalCount:0
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTaskLoading(state, action) {
      state.isTaskLoading = action.payload;
    },
    setStatusLogLoading(state, action) {
      state.isStatusLogLoading = action.payload;
    },
    setTasksList(state, action) {
      const { tasks, tasks_count, active_count, draft_count, total_count  } = action.payload;
      state.tasksData = tasks;
      state.activeCount = active_count;
      state.totalCount = total_count;
      state.draftCount = draft_count;
      state.totalData = tasks_count;
    },
    setAssignedTasks(state, action) {
      const { assigned_tasks, total_count } = action.payload;
      state.assignedTasks = assigned_tasks;
      state.totalData = total_count;
    },
    setTasksStatuses(state, action) {
      const { tasks_statuses } = action.payload;
      state.tasksStatuses = tasks_statuses;
    },
    setTasksDetails(state, action) {
      const { taskDetail } = action.payload;
      state.taskDetails = taskDetail;
    },
    setParentTaskDetails(state, action) {
      const { documents,status_change_logs } = action.payload;
      state.taskDetails = action.payload;
      state.taskDocuments = documents;
      state.statusLogs = status_change_logs;
    },
    setTaskStatusLogs(state, action) {
      const { status_logs } = action.payload;
      state.statusLogs = status_logs;
    },
    setLocationList(state, action) {
      state.locations = action.payload
    },
    setTasksError(state, action) {
      state.error = action.payload;
    },
  },
});
export const {
  setLoading,
  setTaskLoading,
  setLocationList,
  setStatusLogLoading,
  setTasksList,
  setTasksError,
  setTasksDetails,
  setAssignedTasks,
  setTasksStatuses,
  setParentTaskDetails,
  setTaskStatusLogs,
} = tasksSlice.actions;

export default tasksSlice.reducer;
const handleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.status) {
      dispatch(setTasksError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error;
  }
};

const taskHandleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setTaskLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setTaskLoading(false));
    return response;
  } catch (error) {
    dispatch(setTaskLoading(false));
    if (error?.response?.status) {
      dispatch(setTasksError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error;
  }
};

const StatusLogHandleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setStatusLogLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setStatusLogLoading(false));
    return response;
  } catch (error) {
    dispatch(setStatusLogLoading(false));
    if (error?.response?.status) {
      dispatch(setTasksError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error;
  }
};

export const fetchTaskList = createAsyncThunk(
  'tasks/fetchTaskList',
  async (
    {
      page = 0,
      type = '',
      searchTerm = '',
      sort = '',
      sortKey = '',
      startDate = '',
      endDate = '',
      teamId = '',
    }: {  
        page?: number;
        employeeId?: string;
        searchTerm?: string;
        teamId?: string;
        sort?: string;
        sortKey?: string;
        type?: string;
        startDate?: any;
        endDate?: any
      },
    { dispatch }
  ) => {

    const params = {
      search: searchTerm,
      skip: `${page * 5}`,
      limit: '5',
      sort,
      sort_key: sortKey,
      type,
      start_date: startDate,
      end_date: endDate,
      team_id: teamId
    };

    const request = () => axios.get(Api.tasks, { params });
    await handleAsyncThunk(dispatch, request, setTasksList);

  }
);

export const FetchStatusLogs = createAsyncThunk(
  'task/status-logs',
  async (
    {
      taskId = '',
      searchTerm = '',
    }: { taskId?: string , searchTerm?: string
    },
    { dispatch }
  ) => {

    const params = {
      task_id: taskId,
      search: searchTerm,
    };
    const request = () => axios.get(Api.taskStatusLog, { params });
    await StatusLogHandleAsyncThunk(dispatch, request, setTaskStatusLogs);
  }
);


export const fetchTaskDetails = createAsyncThunk(
  'tasks/fetchTaskDetails',
  async (
    {
      taskId = 0,
    }: { taskId?: number },
    { dispatch }
  ) => {

    const params = {
      task_id: taskId,
    };

    const request = () => axios.get(Api.taskDetail, { params });
    await handleAsyncThunk(dispatch, request, setTasksDetails);

  }
);

export const deleteTask = createAsyncThunk('tasks/deleteTask', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.deleteTask, data);
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const changeTaskStatus = createAsyncThunk('task/status-change', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.changeStatus, data);
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});
export const editTask = createAsyncThunk('tasks/editTasks', async (data: any, { dispatch }) => {

 
 
  try {
    data.task_id = data.uuid;
    delete data.uuid;
    const response = await axios.post(Api.editTask,  data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return { success: true };
  }  catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});


export const FetchTaskDetails = createAsyncThunk(
  'task/details',
  async (
    {
      taskId = '',
    }: { taskId?: string },
    { dispatch }
  ) => {

    const params = {
      task_id: taskId,
    };
    const request = () => axios.get(Api.taskDetail, { params });
    await taskHandleAsyncThunk(dispatch, request, setParentTaskDetails);
  }
);



export const addTask = createAsyncThunk('tasks/addTask', async (data: any, { dispatch }) => {
  try {
    // await axios.post(Api.addTask, data);
    const response = await axios.post(Api.addTask, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error?.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const fetchAssignedTasks = createAsyncThunk(
  'tasks/fetchAssignedTasks',
  async (
    {
      page = 0,
      type = '',
      searchTerm = '',
      sort = '',
      sortKey = '',
      startDate = '',
      employeeId = '',
      teamId = '',
      endDate = '',
      traineeId = '',
      taskId = '',
    }: {  
        page?: number;
        employeeId?: string;
        searchTerm?: string;
        teamId?: string;
        sort?: string;
        sortKey?: string;
        type?: string;
        startDate?: any;
        endDate?: any
        traineeId?: string | null;
        taskId?: string ;
      },
    { dispatch }
  ) => {

    const params = {
      search: searchTerm,
      skip: `${page * 5}`,
      limit: '5',
      sort,
      sort_key: sortKey,
      type,
      start_date: startDate,
      end_date: endDate,
      team_id: teamId,
      trainee_id:traineeId,
      task_id:taskId
    };

    console.log("page number form sclice", page);
    const request = () => axios.get(Api.assignedTasks, { params });
    await handleAsyncThunk(dispatch, request, setAssignedTasks);

  }
);


export const FetchStatus = createAsyncThunk(
  'tasks/FetchStatus',
  async (
    {
      taskId = 0,
    }: { taskId?: number },
    { dispatch }
  ) => {

    const params = {
      task_id: taskId,
    };

    const request = () => axios.get(Api.taskStatuses, { params });
    await handleAsyncThunk(dispatch, request, setTasksStatuses);
  }
);

export const changeStatus = createAsyncThunk('tasks/changeStatus', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.changeStatus, data);
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error?.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});
// , changeStatus, FetchStatus
