import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { FinalReviewState } from '../model/finalReview.model';


export interface FinalReview {
  uuid?: string;
  name?: string;
  mentor_rating?: number | null;
  lead_rating?: number | null;
}

const initialState: FinalReviewState = {
  isLoading: false,
  finalReviewsData: [],
  finalReviewsDetails:{},
  finalReviewOverallData:[],
  finalReviewFactorData:[],
  reviewTypes:[],
  finalReviewsCount:0,
  error: {},
};

export const finalReveiwSlice = createSlice({
  name: 'finalReview',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setFinalReviewList(state, action) {
      const finalReviews = action.payload.final_reviews;
      const finalReviewsCount = action.payload.count;
      state.finalReviewsData = finalReviews;
      state.finalReviewsCount = finalReviewsCount;
    },
    setFinalReviewDetails(state, action) {
      const finalReviewDetails = action.payload;
      state.finalReviewsDetails = finalReviewDetails;
      state.finalReviewOverallData=finalReviewDetails[0];
      state.finalReviewFactorData=finalReviewDetails.slice(1);
    },
    setReviewTypes(state, action) {
      const reviewTypes = action.payload;
      state.reviewTypes = reviewTypes;
    },
    setAssignedTasks(state, action) {
      const assignedTasks = action.payload;
      state.assignedTasks = assignedTasks.assigned_tasks;
    },

    setTasksError(state, action) {
      state.error = action.payload;
    },
  },
});
export const {
  setFinalReviewList,
  setFinalReviewDetails,
  setReviewTypes,
  setAssignedTasks,
  setLoading,
  setTasksError
} = finalReveiwSlice.actions;

export default finalReveiwSlice.reducer;

const handleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.status) {
      dispatch(setTasksError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error;
  }
};

export const fetchFinalReviewList = createAsyncThunk(
  'tasks/fetchFinalReviewList',
  async (
    {
      page = 0,
      type = '',
      searchTerm = '',
      sort = '',
      sortKey = '',
      startDate = '',
      endDate = '',
      teamId = '',
      reviewTypeId = '',
      traineeID = '',
    }: {  
        page?: number;
        employeeId?: string;
        searchTerm?: string;
        teamId?: string;
        sort?: string;
        sortKey?: string;
        type?: string;
        startDate?: any;
        endDate?: any;
        reviewTypeId?:any;
        traineeID?:string;
      },
    { dispatch }
  ) => {

    const params = {
      search: searchTerm,
      skip: `${page * 5}`,
      limit: '5',
      sort,
      sort_key: sortKey,
      type,
      team_uuid:teamId,
      start_date: startDate,
      end_date: endDate,
      review_type_filter_uuid: reviewTypeId,
      trainee_id : traineeID
    };

    const request = () => axios.get(Api.listFinalReview, { params });
    await handleAsyncThunk(dispatch, request, setFinalReviewList);
  }
);

export const fetchFinalReviewDetails = createAsyncThunk(
  'tasks/fetchFinalReviewDetails',
  async (
    {
      finalReview = ''

    }: {  

      finalReview?: string
      
    },
    { dispatch }
  ) => {

    const params = {
      final_review: finalReview
    };
    
    const request = () => axios.get(Api.finalReviewDetails, { params });
    await handleAsyncThunk(dispatch, request, setFinalReviewDetails);
  }
);

export const addReview = createAsyncThunk('finalreview/add', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.addFinalReview, data);
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error?.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const lsitReviewTypes = createAsyncThunk('finalreview/reviewtype', async (data: any, { dispatch }) => {
  try {
    const request = () => axios.get(Api.listReviewTypes);
    await handleAsyncThunk(dispatch, request, setReviewTypes);
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error?.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

// export const lsitAssignedTasks = createAsyncThunk('finalreview/assignedTasks', async (data: any, { dispatch }) => {
//   try {
//     const request = () => axios.get(Api.assignedTasks);
//     await handleAsyncThunk(dispatch, request, setAssignedTasks);
//     // dispatch(fetchTeamList());
//     return { success: true }; // Return success flag
//   } catch (error) {
//     if (error?.data?.length === 0) {
//       // Custom message handled
//       dispatch(setTasksError(error?.message));
//     } else {
//       // Specific error message handled
//       const errorMessages = Object.values(error?.data).flat();
//       dispatch(setTasksError(errorMessages));
//     }
//     throw error; // Throw the error to indicate failure
//   }
// });

export const lsitAssignedTasks = createAsyncThunk(
  'finalreview/assignedTasks',
  async (
    {
      trainee_id = ''

    }: {  

      trainee_id?: string
      
    },
    { dispatch }
  ) => {

    const params = {
      trainee_id
    };
    
    const request = () => axios.get(Api.assignedTasks, { params });
    await handleAsyncThunk(dispatch, request, setAssignedTasks);
  }
);
