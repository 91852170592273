import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Unauthorized API response
      // Redirect to login or perform any other unauthorized handling
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
