import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavAccount from './NavAccount';
import NavToggleButton from './NavToggleButton';
import { EndStyledFlex } from './styles';
import en from '../../../locales/langs/en';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

type NavConfigItem = {
  subheader: string;
  items: {
    title: string;
    path: string;
    icon: JSX.Element;
    permission: string;
    permission_label: string;
  }[];
};

type Permissions = {
  [key: string]: string[];
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const storedPermissions = localStorage.getItem('permissions');
  const [currentNavConfig, setCurrentNavConfig] = useState<NavConfigItem[]>(navConfig);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (storedPermissions) {
      const parsedPermissions: Permissions = JSON.parse(storedPermissions);
      setCurrentNavConfig(filterNavConfig(parsedPermissions, navConfig));
    }
  }, [storedPermissions]);

  const filterNavConfig = (permissions: Permissions, fullNavConfig: NavConfigItem[]): NavConfigItem[] =>
  fullNavConfig.map(item => ({
    ...item,
    items: item.items.filter(subItem => {
      const subItemPermissions = permissions[subItem.permission_label];
      return subItemPermissions && subItemPermissions.includes(subItem.permission);
    })
  }));
  
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <EndStyledFlex>
          <Typography variant="h5" sx={{ mx: 'auto', my: '1rem' }}>{en.app_name}</Typography>
        </EndStyledFlex>
        <NavAccount />
      </Stack>

      <NavSectionVertical data={currentNavConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      <NavToggleButton />

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
