// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
// components
import { CustomAvatar } from '../../../components/custom-avatar';

import { DefaultStore } from '../../../model/store.model';

// ----------------------------------------------------------------------

export const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const userData = JSON.parse(localStorage.getItem('user') ?? 'null');
  return (
    <StyledRoot>
      <CustomAvatar src={userData?.profile_picture} alt={userData?.name} name={userData?.name} />

      <Box sx={{ ml: 2, minWidth: 0 }}>
        <Typography variant="subtitle2" noWrap>
          {userData?.name}
        </Typography>

        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          {userData?.role_name}
        </Typography>
      </Box>
    </StyledRoot>
  );
}
