import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';

interface DashboardState {
  isLoading: boolean;
  dashboardData: any;
  attendanceData: any;
  skillsData: any;
  error: {};
}

const initialState: DashboardState = {
  isLoading: false,
  dashboardData: {},
  attendanceData: {},
  skillsData: [],
  error: {},
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
      state.error = {};
    },
    setDashboardList(state, action) {
      state.dashboardData = action.payload;
    },
    setDashboardError(state, action) {
      state.error = action.payload;
    },
    setDashboardAttendance(state, action) {
      state.attendanceData = action.payload.attendance;
    },
    setDashboardSkills(state, action) {
      state.skillsData = action.payload.skill_stats;
    }
  },
});

export const { setLoading, setDashboardList, setDashboardAttendance,setDashboardSkills, setDashboardError } = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const fetchDashboardList = createAsyncThunk('dashboard/fetchDashboardList', async (uuid: string, { dispatch }) => {
  try {
    const params: Record<string, string> = {};
    if (uuid !== "") (params.location_uuid = uuid);

    dispatch(setLoading(true));
    const response = await axios.get(Api.dashboard, { params });

    dispatch(setDashboardList(response?.data?.data));
    dispatch(setLoading(false));
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setDashboardError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setDashboardError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const fetchDashboardGraph = createAsyncThunk('dashboard/fetchDashboardGraph', async (uuid: string, { dispatch }) => {
  try {
    const params: Record<string, string> = {};
    if (uuid !== "") (params.location_uuid = uuid);

    dispatch(setLoading(true));
    const response = await axios.get(Api.graphs, { params });

    dispatch(setDashboardAttendance(response?.data?.data));
    dispatch(setDashboardSkills(response?.data?.data));
    dispatch(setLoading(false));
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setDashboardError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setDashboardError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});


