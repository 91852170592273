import React, { ErrorInfo, ReactNode } from 'react';
import { m } from 'framer-motion';
// @mui
import { Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { SeverErrorIllustration } from '../assets/illustrations';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    // componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can log the error to an error reporting service
    // console.error(error, errorInfo);
    // }

    render(): ReactNode {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return <div>
                <MotionContainer>
                    <m.div variants={varBounce().in}>
                        <Typography variant='h4' sx={{ color: 'text.secondary', textAlign: 'center', marginTop: '4rem' }}>
                            Something went wrong. Please try again later.
                        </Typography>
                    </m.div>
                    <m.div variants={varBounce().in}>
                        <SeverErrorIllustration
                            sx={{
                                height: 260,
                                my: { xs: 5, sm: 10 },
                            }}
                        />
                    </m.div>
                </MotionContainer></div>
        }

        // Ensure children is always an array
        const childrenArray = React.Children.toArray(children);

        return childrenArray;
    }
}

export default ErrorBoundary;
