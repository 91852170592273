import { useEffect, useState } from 'react';
// @mui
import { Stack, Box } from '@mui/material';
// config
import { NAV } from '../../../config-global';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavToggleButton from './NavToggleButton';

// ----------------------------------------------------------------------

type NavConfigItem = {
  subheader: string;
  items: {
    title: string;
    path: string;
    icon: JSX.Element;
    permission: string;
    permission_label: string;
  }[];
};

type Permissions = {
  [key: string]: string[];
};

export default function NavMini() {
  const storedPermissions = localStorage.getItem('permissions');
  const [currentNavConfig, setCurrentNavConfig] = useState<NavConfigItem[]>(navConfig);

  useEffect(() => {
    setCurrentNavConfig(navConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navConfig]);


  useEffect(() => {
    if (storedPermissions) {
      const parsedPermissions: Permissions = JSON.parse(storedPermissions);
      setCurrentNavConfig(filterNavConfig(parsedPermissions, navConfig));
    }
  }, [storedPermissions]);

  const filterNavConfig = (permissions: Permissions, fullNavConfig: NavConfigItem[]): NavConfigItem[] =>
  fullNavConfig.map(item => ({
    ...item,
    items: item.items.filter(subItem => {
      const subItemPermissions = permissions[subItem.permission_label];
      return subItemPermissions && subItemPermissions.includes(subItem.permission);
    })
  }));

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          my: 12,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        {/* <Logo sx={{ mx: 'auto', my: 2 }} /> */}

        <NavSectionMini data={currentNavConfig} />
      </Stack>
    </Box>
  );
}
