import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// components
import axios from '../utils/axios';
import Api from '../utils/apiUrls';

const initialState = {
  userData: {},
  error: '',
  projectTypes: [],
  allSkills: [],
  isLoading: false,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile(state, action) {
      const { user = {}, project_types = [] } = action.payload;
      state.userData = user;
      state.projectTypes = project_types;
    },
    setAllSkills(state, action) {
      state.allSkills = action.payload;
    },
    setUserProfileError(state, action) {
      state.error = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setUserProfile,
  setUserProfileError,
  setAllSkills,
  setLoading,
} = user.actions;
export default user.reducer;


const handleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.status) {
      dispatch(setUserProfileError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setUserProfileError(errorMessages));
    }
    throw error;
  }
};

export const fetchUserProfile = createAsyncThunk(
  'userProfiles/fetchUserProfile',
  async (user_uuid: string | undefined, { dispatch }) => {
    const params: Record<string, string> = user_uuid ? { user_uuid } : {};
    const request = () => axios.get(Api.userData, { params });
    const response = await handleAsyncThunk(dispatch, request, setUserProfile);
    return { success: true, response: response?.data };
  }
);

export const editProfile = createAsyncThunk(
  'userProfiles/editProfile',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(Api.userData + Api.edit, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const getSkill = createAsyncThunk('userProfiles/getSkill', async (_, { dispatch }) => {
  const request = () => axios.get(Api.skills);
  const response = await handleAsyncThunk(dispatch, request, setAllSkills);
  return { success: true, response: response?.data };
});

export const addSkill = createAsyncThunk('userProfiles/addSkill', async (data: any, { dispatch }) => {
  const request = () => axios.post(Api.userSkills + Api.add, data);
  const response = await handleAsyncThunk(dispatch, request);
  return { success: true, response: response?.data };
});

export const deleteSkill = createAsyncThunk('userProfiles/deleteSkill', async (data: any, { dispatch }) => {
  const request = () => axios.post(`${Api.userSkills + Api.delete}`, data);
  const response = await handleAsyncThunk(dispatch, request);
  return { success: true, response: response?.data };
});

export const addProjects = createAsyncThunk('userProfiles/addProjects', async (data: any, { dispatch }) => {
  const request = () => axios.post(Api.userProjects + Api.add, data);
  const response = await handleAsyncThunk(dispatch, request);
  return { success: true, response: response?.data };
});

export const editProjects = createAsyncThunk(
  'userProfiles/editProjects',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(Api.userProjects + Api.edit, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  });

export const deleteProjects = createAsyncThunk(
  'userProfiles/deleteProjects',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(`${Api.userProjects + Api.delete}`, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  });

export const addCareer = createAsyncThunk('userProfiles/addCareer', async (data: any, { dispatch }) => {
  const request = () => axios.post(Api.userCareer + Api.add, data);
  const response = await handleAsyncThunk(dispatch, request);
  return { success: true, response: response?.data };
});

export const editCareer = createAsyncThunk('userProfiles/editCareer', async (data: any, { dispatch }) => {
  const request = () => axios.post(Api.userCareer + Api.edit, data);
  const response = await handleAsyncThunk(dispatch, request);
  return { success: true, response: response?.data };
});

export const deleteCareer = createAsyncThunk('userProfiles/deleteCareer', async (data: any, { dispatch }) => {
  const request = () => axios.post(`${Api.userCareer + Api.delete}`, data);
  const response = await handleAsyncThunk(dispatch, request);
  return { success: true, response: response?.data };
});