import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { PermissionsState } from '../model/permissions.model';

const initialState: PermissionsState = {
  isLoading: false,
  permissionsBaseData: [],
  myPermissions: [],
  error: {},
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPermissionsList(state, action) {
      state.permissionsBaseData = action.payload.permissions;
    },
    setMyPermissions(state, action) {
      state.myPermissions = action.payload.permissions;
    },
    setPermissionsError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setPermissionsList, setPermissionsError, setMyPermissions } =
  permissionsSlice.actions;
export default permissionsSlice.reducer;

export const fetchPermissionList = createAsyncThunk(
  'permissions/fetchPermissionList',
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      // const response = await axios.get(Api.permissions + Api.list);
      const response = {
        status: true,
        msg: 'Permission list',
        data: {
          permissions: [
            { uuid: 'd274dd67-b68f-44e3-b613-30635dbf3ef3', name: 'logout' },
            { uuid: '2d66c1ea-9fb0-4bca-80d8-1725d83daa30', name: 'update-role-team' },
            { uuid: 'c0e84c5a-8609-412e-b6ab-01d3a42d3493', name: 'profile.update' },
            { uuid: 'b703697f-70da-4291-8c71-428d3daf6fc7', name: 'profile.details' },
            { uuid: '3cd57a98-26c5-42f3-baf8-1c36313eee29', name: 'skills.add' },
            { uuid: '798c855f-40b8-4f69-a976-07dd1b04e4a6', name: 'skills.update' },
            { uuid: '289915d6-60fd-4829-9814-1f9b38c0a5eb', name: 'skills.delete' },
            { uuid: 'eeaaee38-d6db-43a2-abf8-54a6384876bf', name: 'projects.add' },
            { uuid: '737df479-941b-43ef-a83e-051e1a7383cf', name: 'projects.update' },
            { uuid: '41b6d382-fe73-4103-bad1-4f7b3a81a12a', name: 'projects.delete' },
            { uuid: '55ab1679-62ff-4f8b-abdb-31a94cf535f6', name: 'teams.add' },
            { uuid: 'b956ebb0-97cc-4fc5-a329-713dd24d3862', name: 'teams.update' },
            { uuid: 'ef3946da-7952-44e8-8726-dc8aab147fab', name: 'teams.list' },
            { uuid: '72c918c0-ef0a-4117-b03c-c408290cd633', name: 'teams.delete' },
            { uuid: 'd9f6dace-809c-4ec1-acc8-1c41a286011d', name: 'teams.details' },
            { uuid: '07f05e4f-88d9-4f1a-b449-c2d2be155cba', name: 'roles.add' },
            { uuid: '8a966f34-63cd-49b5-b350-506c934df26d', name: 'roles.update' },
            { uuid: '1aaabb40-87d3-417b-ac45-f906624db922', name: 'roles.list' },
            { uuid: 'ce44f758-2071-420c-b787-89b7a0de214b', name: 'roles.details' },
            { uuid: '713bf7f3-7b4e-4561-9039-2d3b08917e1a', name: 'sessions.add' },
            { uuid: '40df4a43-4245-4199-b687-e05bf36f8152', name: 'sessions.add-attendance' },
            { uuid: '33866a5e-fd73-47b3-87d9-ded907969d00', name: 'sessions.update' },
            { uuid: '7d40e520-9f50-4e7f-ab63-21a1e923e5a8', name: 'sessions.list' },
            { uuid: '8b00fe73-5546-44e0-bc43-de2b7e640655', name: 'sessions.delete' },
            { uuid: '7f0a0dc6-4e2b-4ab2-949a-ee092809f23d', name: 'sessions.details' },
            { uuid: 'cfe09183-8787-4117-b143-1d64d02c0e94', name: 'role-permissions.add' },
            { uuid: 'f48b2ab1-316b-45e3-a629-edfd8f89d86d', name: 'role-permissions.update' },
            { uuid: 'b27bee4e-4b29-49d3-95bc-5a1aa42067a5', name: 'users.add' },
            { uuid: 'd55a6916-d727-4537-9ee5-74a081a0b069', name: 'users.update' },
            { uuid: '46c61ae1-62a2-47a2-8ed0-2c9ab2f089e6', name: 'users.list' },
            { uuid: '24409729-6b7e-493f-b7b0-9b9cf4ea44fe', name: 'users.delete' },
            { uuid: '47da309d-4ae2-458f-b0a1-bad700d2a421', name: 'users.details' },
            { uuid: '977563fd-a9d2-420b-85b3-22912bffcb50', name: 'permissions.add' },
            { uuid: '239a8f00-5618-4e3d-84e7-a5a6dd652516', name: 'permissions.update' },
            { uuid: '96057648-148a-41d0-b808-0ccf1929aa53', name: 'permissions.list' },
            { uuid: '649fa89c-26bd-4730-941a-10312a602529', name: 'permissions.details' },
            { uuid: '0d2e65b4-bce2-4375-bcca-1bc3ee009e1f', name: 'interviews.add' },
            { uuid: '78ad31f5-f775-45b5-95d4-1b9dc9379308', name: 'interviews.update' },
            { uuid: '9dadc7f2-3e52-430f-a305-ca1440460a6f', name: 'interviews.list' },
            { uuid: 'd157c84f-5617-4329-9be7-4b1ed7938ec4', name: 'interviews.delete' },
            { uuid: '99138b85-90c3-4a09-91bf-ae9a6cf5624c', name: 'interviews.details' },
          ],
        },
      };
      dispatch(setPermissionsList(response?.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setPermissionsError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setPermissionsError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const fetchMyPermissions = createAsyncThunk(
  'permissions/fetchMyPermissions',
  async (_, { dispatch }) => {
    try {
      //  const response = await axios.post(Api.permissions + Api.edit, data);
      const response = {
        status: true,
        msg: 'Role permissions list',
        data: {
          permissions: [
            'd274dd67-b68f-44e3-b613-30635dbf3ef3',
            '3cd57a98-26c5-42f3-baf8-1c36313eee29',
            '7a94bcc0-30d8-4423-91b3-96f6de4bbb44',
            '41b6d382-fe73-4103-bad1-4f7b3a81a12a',
            '5a984a08-c474-4343-be0b-765f9bcd2f7a',
            '2e2610b1-ae49-466f-839c-8fc76c35e683',
            '72bff6fe-8116-4d6b-8a86-700326d2ad20',
            'ea5842bf-59d7-41ce-b56c-3406214779e2',
            '3ef06667-ac85-4119-83c9-559fbdff8fa8',
            'e72f3eff-12c2-45fa-9917-15a110ca87fd',
            'a2ae1a1e-1d14-4a45-b3f1-c02d6a80545d',
            'a298a67a-5953-468a-a397-a2f9dd186e5d',
            'fe910140-3ea0-4c8e-b659-7a0686174ad7',
            '2b20ede8-009c-44d6-9f59-8c2c8d5e03a9',
            '1fb2a3ab-84b5-49e5-a156-ccbfd7d88d7a',
            'dbce7ac5-d1d7-4bfa-8b7a-f18ff7dcc7a7',
            'ddba654c-cdec-4e8d-8812-9183106ab8bd',
            '30631241-3163-41c6-9081-cea318d11826',
            '9367d3c5-3667-4007-9d87-47c7ccd32b48',
            '02b2ac21-1c4c-4cc4-b1b2-27e680dd7c17',
            'be2a3d9f-2fcb-4ade-82de-70e5811e53ad',
            '0718466b-6872-4d21-bb71-40b6aa619eca',
            'f52ac507-5962-4665-88be-3b0cff348329',
            '7a91027a-b653-465c-9aa2-dc4cbeeafb8c',
            'ff0a9e44-0330-493f-9a6d-f37b1fa0e5ba',
            '17f64251-5587-4615-879d-577e587ed997',
            'da76af57-a24f-44d5-ab2c-94dbf7c2173a',
            '0c758602-d089-48b5-babf-46a02c9f0021',
            '21042352-5a32-4029-8bc8-6ce205632492',
            '769a49ba-da54-480c-a488-fed4adab3b53',
            'c6c683a7-6127-4361-ab6e-3691b5feb261',
            '10d6d7ac-0ffb-4872-8169-d96f9b3e2e6e',
            '7ec60fde-30e1-49f9-a7fd-83161c41db07',
            '278e89ab-df3d-4257-b43e-18eb8d4be77e',
            '490e1275-f0f0-44c6-bccd-9b2db9b4bfe6',
            '7052be65-6da8-46a6-8345-75d106ab0c38',
            '245b2013-1fb5-4abf-90fc-46aa71e76866',
            'e5431be3-9298-4180-b661-ead921ecf7b4',
            'e1f10356-bf21-4700-b12b-808a499319b0',
            '7e614909-0947-4907-ba5b-0fc5e203794b',
            '09e928ad-871c-4dd7-ae84-748ea2e87e9c',
            '9fab35f9-6b6e-4af5-85fe-8fcf69042ffc',
            '71d3cf47-16e0-4973-9521-97a3f169103d',
            'f816a891-674e-4b21-808a-f72e6428292c',
          ],
        },
      };
      dispatch(setMyPermissions(response?.data));
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setPermissionsError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setPermissionsError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);
