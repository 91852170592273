import { combineReducers } from '@reduxjs/toolkit';

import auth from '../../slices/authSlice';
import user from '../../slices/userSlice';
import teams from '../../slices/teamsSlice';
import employees from '../../slices/employeeSlice';
import interviews from '../../slices/interviewSlice';
import permissions from '../../slices/permissionsSlice';
import sessions from '../../slices/sessionSlice';
import attendance from '../../slices/attendanceSlice';
import dashboard from '../../slices/dashboardSlice';
import tasks from '../../slices/tasksSlice';
import status from '../../slices/dailyStatusSlice';
import trainees from '../../slices/traineeSlice';
import finalReview from '../../slices/finalReviewSlice';


const reducerList = {
  auth,
  user,
  teams,
  employees,
  interviews,
  permissions,
  sessions,
  attendance,
  dashboard,
  tasks,
  status,
  trainees,
  finalReview
};

const rootReducer = combineReducers(reducerList);

export default rootReducer;
